import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import BBCodeParser from '@cotyorg/ccx-utils/js/components/BBCodeParser/components/BBCodeParser/BBCodeParser';
import Heading from '@cotyorg/ccx-atoms/Heading';

const generateSectionTextCssClass = ({ isBanner, alignCenter, extraClass }) =>
  className('section__text', {
    'section__text--banner': isBanner,
    'section__text--align-center': alignCenter,
    [extraClass]: extraClass,
  });

const SectionText = ({ children, isBanner, alignCenter, extraClass }) => (
  <section
    className={generateSectionTextCssClass({
      isBanner,
      alignCenter,
      extraClass,
    })}
  >
    <Heading level={2}>
      <BBCodeParser>{children}</BBCodeParser>
    </Heading>
  </section>
);

SectionText.propTypes = {
  children: PropTypes.node.isRequired,
  isBanner: PropTypes.bool,
  alignCenter: PropTypes.bool,
  extraClass: PropTypes.string,
};

export default SectionText;
