import { combineReducers } from 'redux';
import { content as contentReducer } from '@cotyorg/ccx-server/modules';
import breakpointReducer from '@cotyorg/ccx-utils/js/components/BreakpointProvider/modules';
import carouselReducer from '@cotyorg/ccx-organism-carousel/modules';
import cookieReducer from '@cotyorg/ccx-organism-cookie/modules';
import countrySelectorReducer from '@cotyorg/ccx-organism-country-selector/modules';
import headerReducer from '@cotyorg/ccx-organism-header/modules';
import lazyLoadReducer from '@cotyorg/ccx-utils/js/components/LazyLoad/modules';
import navigationReducer from '@cotyorg/ccx-organism-navigation/modules';
import pictureReducer from '@cotyorg/ccx-atoms/Picture/modules';
import searchReducer from '@cotyorg/ccx-organism-search/modules';
import accordionReducer from '@cotyorg/ccx-molecules/Accordion/modules';
import videoReducer from '@cotyorg/ccx-organism-video/modules';
import dragSwipeReducer from '@cotyorg/ccx-utils/js/components/DragSwipe/modules';
import newsletterReducer from '@cotyorg/ccx-molecules/Newsletter/modules';
import productSwatchReducer from '@cotyorg/ccx-organism-product-swatch/modules';

const rootReducer = combineReducers({
  breakpoints: breakpointReducer,
  accordions: accordionReducer,
  carousels: carouselReducer,
  content: contentReducer,
  dragSwipe: dragSwipeReducer,
  cookie: cookieReducer,
  countrySelector: countrySelectorReducer,
  header: headerReducer,
  lazyLoads: lazyLoadReducer,
  navigation: navigationReducer,
  pictures: pictureReducer,
  search: searchReducer,
  status: (state = {}) => state,
  videos: videoReducer, // TODO: [1] this should be video?
  newsletter: newsletterReducer,
  productSwatch: productSwatchReducer,
  location: (state = {}) => state,
});

export default rootReducer;
