import React from 'react';
import PropTypes from 'prop-types';
import Heading from '@cotyorg/ccx-atoms/Heading';
import Link from '@cotyorg/ccx-atoms/Link';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import SectionText from '../../molecules/SectionText';
import SectionHeader from '../../atoms/SectionHeader';

const PageDescription = ({ heading, text, link, url, discoverButtonLabel }) => (
  <div className="grid-container">
    <div className="grid-x grid-margin-x">
      <div className="cell xsmall-14 small-12 medium-10 xsmall-offset-1 small-offset-2 medium-offset-3">
        <div className="page-description">
          <OptionalSection
            renderCondition={!!heading}
            section={() => (
              <SectionHeader isBanner>
                <Heading extraClass="section__title--banner" level={1}>
                  {heading}
                </Heading>
              </SectionHeader>
            )}
          />

          <OptionalSection
            renderCondition={!!text}
            section={() => (
              <SectionText isBanner alignCenter>
                {text}
              </SectionText>
            )}
          />

          <OptionalSection
            renderCondition={!!link}
            section={() => (
              <Link extraClass="page-description__link" link={url}>
                {discoverButtonLabel}
              </Link>
            )}
          />
        </div>
      </div>
    </div>
  </div>
);

PageDescription.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.bool,
  discoverButtonLabel: PropTypes.string,
  url: PropTypes.string,
};

export default PageDescription;
