import R from 'ramda';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterTwoRow } from '@cotyorg/ccx-organism-footer';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { SocialNewsletterHalfWidth } from '@cotyorg/ccx-organism-social-newsletter';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';

const StandardLayout = ({ children, cookieContent, isOneTrustEnabled }) => (
  <>
    <HeaderTwoRow
      logoWidth={332}
      logoHeight={57}
      search
      logoSrc="/ccx-files/assets/marc-jacobs-logo.svg"
      logoPosition="center"
      logoAlt="Marc Jacobs"
      chevronIconSrc="/ccx-files/assets/chevron-down.svg"
      leftChevronIconSrc="/ccx-files/assets/arrow-left.svg"
      rightChevronIconSrc="/ccx-files/assets/arrow-right.svg"
      closeIconSrc="/ccx-files/assets/cross.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      navType="streamlined"
    />

    {children}

    <div className="grid-container">
      <SocialNewsletterHalfWidth
        socialNewsletterStoreDataKey="SocialNewsletterHalfWidth"
        headingLevel={2}
        linkTarget="_blank"
        socialIconSrc={{
          facebook: '/ccx-files/assets/facebook.svg',
          instagram: '/ccx-files/assets/instagram.svg',
          tiktok: '/ccx-files/assets/tiktok.svg',
        }}
        socialIconWidth={26}
      />
    </div>

    <FooterTwoRow
      minusIconSrc="/ccx-files/assets/close-icon.svg"
      plusIconSrc="/ccx-files/assets/plus-icon.svg"
      navigationLinkIconWidth={26}
      headingLevel={2}
    />

    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </>
);

StandardLayout.propTypes = {
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  isOneTrustEnabled: PropTypes.bool,
};

export default connect(({ content }) => ({
  cookieContent: R.prop('cookie')(content),
  isOneTrustEnabled: R.prop('isOneTrustEnabled')(content),
}))(StandardLayout);
