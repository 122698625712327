import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

const generateSectionHeaderCssClass = ({ isBanner, extraClass }) =>
  className('section__header', {
    'section__header--banner': isBanner,
    [extraClass]: extraClass,
  });

const SectionHeader = ({ children, isBanner, extraClass }) => (
  <header className={generateSectionHeaderCssClass({ isBanner, extraClass })}>
    {children}
  </header>
);

SectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  extraClass: PropTypes.string,
  isBanner: PropTypes.bool,
};

export default SectionHeader;
