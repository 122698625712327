/* eslint-disable global-require */
import universal from 'react-universal-component';
import { setupRouter } from '@cotyorg/ccx-server/router';
import { safeImport } from '@cotyorg/ccx-server/utils';
import Fragrance from './pages/Fragrance';

const ErrorPage = universal(import('./pages/Error'));
const NotFound = universal(import('./pages/NotFound'));
const Home = universal(import('./pages/Home'));
const New = universal(import('./pages/New'));
const WhereToBuy = universal(import('./pages/WhereToBuy'));
const Product = universal(import('./pages/Product'));
const MjDaisy = universal(import('./pages/MjDaisy'));
const FriendsOfDaisy = universal(import('./pages/FriendsOfDaisy'));
const Perfect = universal(import('./pages/Perfect'));
const FragrancePerfectMarcJacobs = universal(
  import('./pages/FragrancePerfectMarcJacobs')
);
const EVSFEventTC = universal(import('./pages/EVSFEventTC'));
const EDTVDayTC = universal(import('./pages/EDTVDayTC'));
const Pride = universal(import('./pages/Pride'));
const QuizPage = universal(import('./pages/QuizPage'));
const Page = universal(import('./pages/Page'));
// const PerfectPrideBall = universal(import('./pages/PerfectPrideBall'));
// const PerfectValentine = universal(import('./pages/PerfectValentine'));

const routerConfig = {
  helperComponents: {
    ErrorComponent: ErrorPage,
  },

  defaultPages: {
    homeOverrides: {
      component: Home,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/home').default
      ),
    },
    notFoundOverrides: {
      component: NotFound,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/not-found').default
      ),
    },
    productOverrides: {
      component: Product,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/product').default
      ),
    },
  },

  additionalRoutes: [
    // This path is only to be used by ReactRouter Config, accessing this page directly is an edge case
    {
      path: '/:locale/error',
      exact: true,
      id: 'error',
      title: 'Error',
      component: ErrorPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/error').default
      ),
    },
    {
      path: '/:locale/new',
      exact: true,
      id: 'new',
      title: 'New',
      component: New,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/new').default
      ),
    },
    {
      path: '/:locale/fragrance',
      exact: true,
      id: 'fragrance',
      title: 'Fragrance',
      component: Fragrance,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/fragrance').default
      ),
    },
    {
      path: '/:locale/where-to-buy',
      exact: true,
      id: 'where-to-buy',
      title: 'Where to buy',
      component: WhereToBuy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/where-to-buy').default
      ),
    },
    {
      path: '/:locale/mj-daisy',
      exact: true,
      id: 'mj-daisy',
      title: 'Mj Daisy',
      component: MjDaisy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/mj-daisy').default
      ),
    },
    {
      path: '/:locale/friends-of-daisy',
      exact: true,
      id: 'friends-of-daisy',
      title: 'Friends of Daisy',
      component: FriendsOfDaisy,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/friends-of-daisy').default
      ),
    },
    {
      path: '/:locale/fragrance/perfect-marcjacobs',
      exact: true,
      id: 'fragrance-perfect-marcjacobs',
      title: 'Fragrance Perfect MarcJacobs',
      component: FragrancePerfectMarcJacobs,
      buildPreloadedState: safeImport(
        () =>
          require('../../server/hydrate/pages/fragrance-perfect-marcjacobs')
            .default
      ),
    },
    {
      path: '/:locale/fragrance/:product',
      exact: true,
      id: 'perfect',
      title: 'Perfect',
      component: Perfect,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/perfect').default
      ),
    },
    {
      path: '/:locale/mj-daisy-sweepstakes-terms-and-conditions',
      exact: true,
      id: 'evsf_event_t_c',
      title: 'Daisy EVSF Event Sweepstakes Terms & Conditions',
      component: EVSFEventTC,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/evsf_event_t_c').default
      ),
    },
    {
      path: '/:locale/mj-perfect-vday-terms-and-conditions',
      exact: true,
      id: 'edtv_day_t_c',
      title: 'Perfect-Edt-Vday Terms & Conditions',
      component: EDTVDayTC,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/perfect-edt-t-c').default
      ),
    },
    {
      path: '/:locale/perfect-pride',
      exact: true,
      id: 'perfect-pride',
      title: 'Perfect-Pride',
      component: Pride,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/pride').default
      ),
    },
    // This template is left in case of later use
    // {
    //   path: '/:locale/perfect-pride-ball',
    //   exact: true,
    //   id: 'perfect-pride-ball',
    //   title: 'Perfect-Pride-Ball',
    //   component: PerfectPrideBall,
    //   buildPreloadedState: safeImport(
    //     () => require('../../server/hydrate/pages/perfect-pride-ball').default
    //   ),
    // },
    {
      path: '/:locale/daisy-productfinder',
      exact: true,
      id: 'daisy-productfinder',
      title: 'Daisy Product Finder',
      component: QuizPage,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/quiz-page').default
      ),
    },
    {
      path: '/:locale/fragrance-finder-terms-conditions',
      exact: true,
      id: 'fragrance-finder-terms-conditions',
      title: 'Page',
      component: Page,
      buildPreloadedState: safeImport(
        () => require('../../server/hydrate/pages/page').default
      ),
    },
    // This template is left in case of later use
    // {
    //   path: '/:locale/perfect-valentine',
    //   exact: true,
    //   id: 'perfect-valentine',
    //   title: 'PerfectValentine',
    //   component: PerfectValentine,
    //   buildPreloadedState: safeImport(
    //     () => require('../../server/hydrate/pages/perfect-valentine').default
    //   ),
    // },
  ],

  routesToRemove: [
    'about',
    'category',
    'subcategory',
    // 'example', // NB: there is no example route, so this won't remove anything - but this is the mechanism
  ],
};

export default setupRouter(routerConfig);
