export const productsBreakpointConfig = {
  xsmall: {
    contentAlignment: {
      horizontal: 'middle',
      vertical: 'bottom',
    },
    gridSetup: {
      image: {
        columns: 16,
      },
      content: {
        columns: 16,
      },
      card: {
        columns: 16,
      },
    },
  },
  small: {
    gridSetup: {
      card: {
        columns: 8,
      },
    },
  },
  medium: {
    gridSetup: {
      card: {
        columns: '5-and-one-third',
      },
    },
  },
};
