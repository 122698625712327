import React from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CardoGroup from '@cotyorg/ccx-organism-cardo-group/components/CardoGroup';
import PageDescription from '../../organisms/PageDescription';
import StandardLayout from '../../templates/StandardLayout';
import { productsBreakpointConfig } from './cardo.config';

const FragranceComponent = ({ sectionHeadings }) => (
  <StandardLayout>
    <PageDescription
      heading={R.path(['introductionSection', 'heading'], sectionHeadings)}
    />
    <div className="product-list">
      <div className="content-wrapper">
        <div className="grid-container">
          <div className="grid-x">
            <div className="cell xsmall-16 large-14 large-offset-1">
              <div className="grid-x grid-padding-x">
                <CardoGroup
                  data="products"
                  isTitleUnderlined={false}
                  contentTheme="transparent"
                  headingLevel={2}
                  shrinkOnHover
                  hideDescription
                  baseBreakpointConfig={productsBreakpointConfig}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </StandardLayout>
);

FragranceComponent.propTypes = {
  sectionHeadings: PropTypes.objectOf(PropTypes.string),
};

export default connect(({ content }) => ({
  sectionHeadings: R.prop('sectionHeadings', content),
  products: R.prop('cardo', content),
}))(FragranceComponent);
